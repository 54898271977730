import React from "react";
import { useEffect, useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import serialize from 'form-serialize';
import axios from "axios";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { Helmet } from "react-helmet"

// import Layout from "../components/layout"
// import Seo from "../components/seo"
// import * as styles from "../components/index.module.css"


const images_dimitra = [
    {
        original: '/dimitra/IMG_20220503_192936_249.jpg',
        thumbnail: '/dimitra/IMG_20220503_192936_249.jpg',
    },
    {
        original: '/dimitra/IMG_20220503_193633_333.jpg',
        thumbnail: '/dimitra/IMG_20220503_193633_333.jpg',
    },
    {
        original: '/dimitra/received_282010660812439.jpeg',
        thumbnail: '/dimitra/received_282010660812439.jpeg',
    },
    {
        original: '/dimitra/received_297842485871440.jpeg',
        thumbnail: '/dimitra/received_297842485871440.jpeg',
    },
    {
        original: '/dimitra/received_298897122433391.jpeg',
        thumbnail: '/dimitra/received_298897122433391.jpeg',
    },
    {
        original: '/dimitra/received_310260017935997.jpeg',
        thumbnail: '/dimitra/received_310260017935997.jpeg',
    },
    {
        original: '/dimitra/received_337420538300735.jpeg',
        thumbnail: '/dimitra/received_337420538300735.jpeg',
    },
    {
        original: '/dimitra/received_339045068295379.jpeg',
        thumbnail: '/dimitra/received_339045068295379.jpeg',
    },
    {
        original: '/dimitra/103.jpg',
        thumbnail: '/dimitra/103.jpg',
    },
    {
        original: '/dimitra/104.jpg',
        thumbnail: '/dimitra/104.jpg',
    },
    {
        original: '/dimitra/105.jpg',
        thumbnail: '/dimitra/105.jpg',
    },
    {
        original: '/dimitra/106.jpg',
        thumbnail: '/dimitra/106.jpg',
    },
    {
        original: '/dimitra/received_392058472793692.jpeg',
        thumbnail: '/dimitra/received_392058472793692.jpeg',
    },
    {
        original: '/dimitra/received_397214455391141.jpeg',
        thumbnail: '/dimitra/received_397214455391141.jpeg',
    },
    {
        original: '/dimitra/received_416075853685659.jpeg',
        thumbnail: '/dimitra/received_416075853685659.jpeg',
    },
    {
        original: '/dimitra/received_554751839662100.jpeg',
        thumbnail: '/dimitra/received_554751839662100.jpeg',
    },
    {
        original: '/dimitra/received_651592922743492.jpeg',
        thumbnail: '/dimitra/received_651592922743492.jpeg',
    },
    {
        original: '/dimitra/received_694397811780810.jpeg',
        thumbnail: '/dimitra/received_694397811780810.jpeg',
    },
    {
        original: '/dimitra/received_706000060818533.jpeg',
        thumbnail: '/dimitra/received_706000060818533.jpeg',
    },
    {
        original: '/dimitra/received_785166472448977.jpeg',
        thumbnail: '/dimitra/received_785166472448977.jpeg',
    },
    {
        original: '/dimitra/received_804368347619372.jpeg',
        thumbnail: '/dimitra/received_804368347619372.jpeg',
    },

    {
        original: '/dimitra/Sak_065.JPG',
        thumbnail: '/dimitra/Sak_065.JPG',
    },
    {
        original: '/dimitra/Sak_073.JPG',
        thumbnail: '/dimitra/Sak_073.JPG',
    },
    {
        original: '/dimitra/Sak_074.JPG',
        thumbnail: '/dimitra/Sak_074.JPG',
    },
    {
        original: '/dimitra/Sak_075.JPG',
        thumbnail: '/dimitra/Sak_075.JPG',
    },
    {
        original: '/dimitra/Sak_082.JPG',
        thumbnail: '/dimitra/Sak_082.JPG',
    },
    {
        original: '/dimitra/Sak_086.JPG',
        thumbnail: '/dimitra/Sak_086.JPG',
    },
    {
        original: '/dimitra/Sak_095.JPG',
        thumbnail: '/dimitra/Sak_095.JPG',
    },
    {
        original: '/dimitra/Sak_132.JPG',
        thumbnail: '/dimitra/Sak_132.JPG',
    },

];

const images_eleni = [
    {
        original: '/eleni/IMG_20220503_192936_229.jpg',
        thumbnail: '/eleni/IMG_20220503_192936_229.jpg',
    },
    {
        original: '/eleni/IMG_20220503_192936_249.jpg',
        thumbnail: '/eleni/IMG_20220503_192936_249.jpg',
    },
    {
        original: '/eleni/received_337420538300735.jpeg',
        thumbnail: '/eleni/received_337420538300735.jpeg',
    },
    {
        original: '/eleni/received_342238767806074.jpeg',
        thumbnail: '/eleni/received_342238767806074.jpeg',
    },
    {
        original: '/eleni/received_347612787354130.jpeg',
        thumbnail: '/eleni/received_347612787354130.jpeg',
    },
    {
        original: '/eleni/received_370291195154958.jpeg',
        thumbnail: '/eleni/received_370291195154958.jpeg',
    },
    {
        original: '/eleni/received_385745780115212.jpeg',
        thumbnail: '/eleni/received_385745780115212.jpeg',
    },
    {
        original: '/eleni/received_387014596654671.jpeg',
        thumbnail: '/eleni/received_387014596654671.jpeg',
    },
    {
        original: '/eleni/received_397132838738401.jpeg',
        thumbnail: '/eleni/received_397132838738401.jpeg',
    },
    {
        original: '/eleni/received_516354583356059.jpeg',
        thumbnail: '/eleni/received_516354583356059.jpeg',
    },
    {
        original: '/eleni/received_522823119317208.jpeg',
        thumbnail: '/eleni/received_522823119317208.jpeg',
    },
    {
        original: '/eleni/received_568759967904120.jpeg',
        thumbnail: '/eleni/received_568759967904120.jpeg',
    },
    {
        original: '/eleni/received_576042814092682.jpeg',
        thumbnail: '/eleni/received_576042814092682.jpeg',
    },
    {
        original: '/eleni/received_670914024207821.jpeg',
        thumbnail: '/eleni/received_670914024207821.jpeg',
    },
    {
        original: '/eleni/received_671579770615867.jpeg',
        thumbnail: '/eleni/received_671579770615867.jpeg',
    },
    {
        original: '/eleni/received_671892614098795.jpeg',
        thumbnail: '/eleni/received_671892614098795.jpeg',
    },
    {
        original: '/eleni/received_695276374897239.jpeg',
        thumbnail: '/eleni/received_695276374897239.jpeg',
    },
    {
        original: '/eleni/received_698684654677527.jpeg',
        thumbnail: '/eleni/received_698684654677527.jpeg',
    },
    {
        original: '/eleni/received_713219219873303.jpeg',
        thumbnail: '/eleni/received_713219219873303.jpeg',
    },
    {
        original: '/eleni/received_727252551798009.jpeg',
        thumbnail: '/eleni/received_727252551798009.jpeg',
    },
    {
        original: '/eleni/received_733210071205491.jpeg',
        thumbnail: '/eleni/received_733210071205491.jpeg',
    },
    {
        original: '/eleni/received_851613349089763.jpeg',
        thumbnail: '/eleni/received_851613349089763.jpeg',
    },
    {
        original: '/eleni/received_1037036706928179.jpeg',
        thumbnail: '/eleni/received_1037036706928179.jpeg',
    },
    {
        original: '/eleni/received_1052569925681984.jpeg',
        thumbnail: '/eleni/received_1052569925681984.jpeg',
    },
    {
        original: '/eleni/received_5159173444166847.jpeg',
        thumbnail: '/eleni/received_5159173444166847.jpeg',
    },

    {
        original: '/eleni/Sak_005.JPG',
        thumbnail: '/eleni/Sak_005.JPG',
    },
    {
        original: '/eleni/Sak_011.JPG',
        thumbnail: '/eleni/Sak_011.JPG',
    },
    {
        original: '/eleni/Sak_015.JPG',
        thumbnail: '/eleni/Sak_015.JPG',
    },
    {
        original: '/eleni/Sak_027.JPG',
        thumbnail: '/eleni/Sak_027.JPG',
    },
    {
        original: '/eleni/Sak_029.JPG',
        thumbnail: '/eleni/Sak_029.JPG',
    },
    {
        original: '/eleni/Sak_051.JPG',
        thumbnail: '/eleni/Sak_051.JPG',
    },
    {
        original: '/eleni/Sak_059.JPG',
        thumbnail: '/eleni/Sak_059.JPG',
    },
];

const IndexPage = () => {

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [emailResponse, setEmailResponse] = useState("")

    useEffect(() => {
        console.log("Mesa");
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });

        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            var navigationEl = document.getElementById("navigation");
            var logoElement = document.getElementById("logo");

            if (currentScrollY === 0) {
                navigationEl.classList.remove('bg-white')
                logoElement.src = "logo-white.png"
            } else {
                navigationEl.classList.add('bg-white')
                logoElement.src = "logo.png"

            }

            console.log(currentScrollY);

        };

        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => window.removeEventListener("scroll", handleScroll);

    }, [])


    function contactMessage(e) {
        e.preventDefault();
        setLoading(true)

        let form = document.querySelector('#contact-form');
        let data = serialize(form, { hash: true });
        console.log(data);
        if (data.name) {
            if (data.email) {
                if (data.subject) {
                    if (data.phone) {
                        if (data.message) {
                            setError("")
                            axios.post(`https://admin.w3vitals.com/wp-json/myplugin/v1/whiteStoneVillas`, data)
                                .then(function (response) {
                                    setEmailResponse(response.data)
                                    setLoading(false)

                                })
                                .catch(function (error) {
                                    // handle error
                                    setLoading(false)
                                })



                        } else {
                            setError('Please enter your message.')
                            setLoading(false)
                        }
                    } else {
                        setError('Please enter your phone.')
                        setLoading(false)
                    }

                } else {
                    setError('Please enter your subject.')
                    setLoading(false)
                }
            } else {
                setError('Please enter your email.')
                setLoading(false)
            }
        } else {
            setError('Please enter your name.')
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <div>
            <Helmet>

                <html lang={"en"} />
                <title>Premium Accommodations | White Stone Villas</title>
                <meta name="description" content={`One of the most exciting and fresh proposals for luxury accommodation in Halkidiki, comes from the twin stone villas "Dimitra" and "Eleni", in the area of Agios Nikolaos, on the Sithonia peninsula in Halkidiki.`} />

            </Helmet>
            <header id="header" className="header-area">
                <div id="navigation" className="navigation">
                    <div className="container">
                        <div className="row">
                            <div className="w-full">
                                <nav className="flex items-center justify-between navbar navbar-expand-md">
                                    <a className="mr-4 navbar-brand" href="index.html">
                                        <img src="logo-white.png" id="logo" width={"250px"} style={{ marginBottom: "0" }} alt="Logo" />
                                    </a>

                                    <div className="absolute left-0 z-30 hidden w-full px-5 duration-300 bg-white shadow md:opacity-100 md:w-auto collapse navbar-collapse md:block top-100 mt-full md:static md:bg-transparent md:shadow-none" id="navbarOne">
                                        <ul className="items-center content-start mr-auto lg:justify-center md:justify-end navbar-nav md:flex m-0">
                                            {/* <li className="nav-item">
                                                <a className="page-scroll" href="#about">ABOUT</a>
                                            </li> */}
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#villas">VILLAS</a>
                                            </li>
                                            {/* <li className="nav-item">
                                                <a className="page-scroll" href="#services">SERVICES</a>
                                            </li> */}
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#map">MAP</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#contact">CONTACT</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="items-center justify-end flex navbar-social lg:hidden">
                                        {/* <button onClick={() => setMenuIsOpen(!menuIsOpen)} className="block navbar-toggler focus:outline-none" type="button" data-toggle="collapse" data-target="#navbarOne" aria-controls="navbarOne" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="toggler-icon"></span>
                                            <span className="toggler-icon"></span>
                                            <span className="toggler-icon"></span>
                                        </button> */}
                                        <div class={`menu btn12 ${menuIsOpen ? "open" : "closed"}`} onClick={() => setMenuIsOpen(!menuIsOpen)} data-menu="13">
                                            <div class="icon"></div>
                                        </div>
                                        <div className={`mobile-menu ${menuIsOpen ? "block" : "hidden"}`}>
                                            <ul className="items-center content-start mr-auto lg:justify-center md:justify-end navbar-nav md:flex m-0">
                                                <li className="nav-item">
                                                    <a className="page-scroll" href="#about">ABOUT</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="page-scroll" href="#villas">VILLAS</a>
                                                </li>
                                                {/* <li className="nav-item">
                                                    <a className="page-scroll" href="#services">SERVICES</a>
                                                </li> */}
                                                <li className="nav-item">
                                                    <a className="page-scroll" href="#map">MAP</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="page-scroll" href="#contact">CONTACT</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="home" className="relative z-10 header-hero" style={{ backgroundImage: "url(header-bg.jpg)" }}>
                    <div className="container h-full">
                        <div className="justify-center row h-full">
                            <div className="flex items-end justify-center h-full w-full lg:w-5/6 xl:w-2/3">
                                <div className="pt-32 pb-24 text-center header-content">
                                    <h1 className="mb-">Always believe that something wonderful is about to happen.</h1>
                                    {/* <p className="px-5 mb-10 intro-text mx-auto hidden md:block">A bed and breakfast is a small lodging establishment that offers overnight accommodation and breakfast.</p> */}
                                    {/* <ul className="flex flex-wrap justify-center">
                                        <li>
                                            <a className="mx-3 btn-anim" href="#about">
                                                <span className="qodef-m-text">VIEW MORE</span>
                                                <span className="qodef-m-background-holder"></span>
                                            </a>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>



            <section id="about" className="py-20">
                <div className="container container-cs">
                    <div className="flex flex-wrap w-full">
                        <div className="w-full lg:w-1/3 mb-16 lg:mb-0">
                            <h2 className="text-right lt-st">
                                THE RIGHT TO LIVE IN PARADISE IT'S WHERE YOU WANT TO BE IN THE HEART OF LUXURY.
                            </h2>
                            <div className="mt-32">
                                <div className="flex justify-end">
                                    <StaticImage
                                        src="../images/mini-map.png"
                                        loading="eager"
                                        width={344}
                                        quality={100}
                                        formats={["auto", "webp", "avif"]}
                                        alt=""
                                    />
                                </div>

                                {/* <h3 className="text-right tr-mb mt-4">Not quite a villa, not a restaurant offer innovative fare for breakfast, dinner, alongside specialty coffee</h3> */}
                                {/* <p className="text-right tb-as mt-4">Martin & Michael – Guests from Norway</p> */}
                            </div>
                        </div>
                        <div className="w-full lg:w-2/3 pl-0 lg:pl-12">
                            <div className="big-l-container">
                                <div>
                                    <span className="big-letter">W</span>
                                </div>
                                <div>
                                    <p style={{ fontSize: "22px" }}>elcome to White Stone Villas "Dimitra" and "Eleni".One of the most exciting and fresh proposals for luxury accommodation in Halkidiki, comes from the twin stone villas "Dimitra" and "Eleni", in the area of Agios Nikolaos, on the Sithonia peninsula in Halkidiki.</p>
                                </div>
                            </div>
                            {/* <p>One of the most exciting and fresh proposals for luxury accommodation in Halkidiki, comes from the twin stone villas "Dimitra" and "Eleni", in the area of Agios Nikolaos, on the Sithonia peninsula in Halkidiki.</p> */}
                            {/* <p className="pt-8">Donec eget viverra velit. Fusce congue turpis quis nunc interdum rutrum. Pellentesque molestie venenatis nibh vitae convallis. Integer nec ipsum at ante dignissim venenatis malesuada ac erat. In euismod, tellus dignissim hendrerit porta, sem mi placerat magna, in dictum lorem velit augue. Praesent vitae mi in est pharetra varius. Quisque felis convallis hendrerit diam ullamcorper eget.</p> */}
                        </div>

                    </div>
                </div>
            </section>
            <section id="villas" style={{ height: "100px" }}>

            </section>

            <section>
                <div className="w-full flex-wrap vl-bg flex">
                    <div className="w-full md:w-2/5 flex items-center justify-start md:justify-end pr-12 order-2 md:order-1">
                        <div className="px-8 text-left md:text-right py-24 w-full">
                            {/* <p className="sld-p">from $45</p> */}
                            <h2 className="sld-h2" style={{ fontSize: "40px" }}>Dimitra</h2>
                            <ul className="sld-ul">
                                <li>
                                    Kitchen: Owen, larger refregerator, pan's, pot's,wine glasses, espresso machine.
                                </li>
                                <li>
                                    Bathroom: Hair dryer, washing machine,clothes rack,hand soap,general purpose cleanes, towels.
                                </li>
                                <li>
                                    Rooms:Bed linens,closet, ironing board,iron,air condition in all rooms.
                                </li>
                                <li>
                                    Terrace:Private BBQ,table chairs,swing.
                                </li>
                                <li>
                                    For the kid's:Baby coat, baby chair, toy's,baby bath.
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className="w-full md:w-3/5 h-auto order-1 md:order-2">
                        <ImageGallery
                            slideInterval={6000}
                            items={images_dimitra}
                            disableThumbnailScroll
                            showThumbnails={false}
                            showPlayButton={false}
                            autoPlay


                        />
                    </div>
                </div>

                <div className="w-full flex-wrap vl-bg flex">
                    <div className="w-full md:w-2/5 flex items-center justify-start pl-0 md:pl-12 order-2">
                        <div className="px-8 text-left py-24 w-full">
                            {/* <p className="sld-p">from $45</p> */}
                            <h2 className="sld-h2 " style={{ fontSize: "40px" }}>Eleni</h2>
                            <ul className="sld-ul">
                                <li>
                                    Kitchen: Owen, larger refregerator, pan's, pot's,wine glasses, espresso machine.
                                </li>
                                <li>
                                    Bathroom: Hair dryer, washing machine,clothes rack,hand soap,general purpose cleanes, towels.
                                </li>
                                <li>
                                    Rooms:Bed linens,closet, ironing board,iron,air condition in all rooms.
                                </li>
                                <li>
                                    Terrace:Private BBQ,table chairs,swing.
                                </li>
                                <li>
                                    For the kid's:Baby coat, baby chair, toy's,baby bath.
                                </li>
                            </ul>
                            {/* <a className="btn-anim" href="javascript:void(0)">
                                <span className="qodef-m-text">MORE PHOTOS</span>
                                <span className="qodef-m-background-holder"></span>
                            </a> */}
                        </div>
                    </div>
                    <div className="w-full md:w-3/5 h-auto order-1">
                        <ImageGallery
                            slideInterval={6000}
                            items={images_eleni}
                            disableThumbnailScroll
                            showThumbnails={false}
                            showPlayButton={false}
                            autoPlay

                        />
                    </div>
                </div>


            </section>

            <section id="services" className="py-20">
                <div className="container container-cs">
                    <div className="flex flex-wrap w-full justify-center" style={{alignItems: "center"}}>
                        <div className="w-full lg:w-1/3 mb-16 lg:mb-0">
                            <StaticImage
                                src="../images/ch-fl-white-background.jpg"
                                loading="eager"
                                width={401}
                                quality={100}
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                className="flex justify-center"
                            />
                        </div>
                        <div className="w-full lg:w-2/3 pl-0 lg:pl-12">
                            <div className="big-l-container flex items-end">
                                <div>
                                    <span className="big-letter" style={{ marginBottom: "0" }}>C</span>
                                </div>
                                <div>
                                    <p style={{ fontSize: "22px", marginBottom: "0", marginTop: "40px" }}>heck in 15:00 & Check out 11:00</p>
                                </div>
                            </div>
                            {/* <p>One of the most exciting and fresh proposals for luxury accommodation in Halkidiki, comes from the twin stone villas "Dimitra" and "Eleni", in the area of Agios Nikolaos, on the Sithonia peninsula in Halkidiki.</p> */}
                            {/* <p className="pt-8">Donec eget viverra velit. Fusce congue turpis quis nunc interdum rutrum. Pellentesque molestie venenatis nibh vitae convallis. Integer nec ipsum at ante dignissim venenatis malesuada ac erat. In euismod, tellus dignissim hendrerit porta, sem mi placerat magna, in dictum lorem velit augue. Praesent vitae mi in est pharetra varius. Quisque felis convallis hendrerit diam ullamcorper eget.</p> */}
                        </div>
                        {/* <div className="w-full lg:w-2/3 pl-0 lg:pl-12">
                            <div className="big-l-container">
                                <div>
                                    <span className="big-letter">W</span>
                                </div>
                                <div>
                                    <p>elcome to White Stone Villas "Dimitra" and "Eleni".One of the most exciting and fresh proposals for luxury accommodation in Halkidiki, comes from the twin stone villas "Dimitra" and "Eleni", in the area of Agios Nikolaos, on the Sithonia peninsula in Halkidiki.</p>
                                </div>
                            </div>
                        </div> */}

                    </div>
                    <div className="flex flex-wrap pt-12">
                        <div className="w-full md:w-1/3 pr-8">
                            <StaticImage
                                src="../images/pet friendly.png"
                                loading="lazy"
                                width={35}
                                quality={100}
                                formats={["auto", "webp", "avif"]}
                                alt=""
                            />
                            <h3 className="val-h3">We are pet friendly</h3>
                            <p>We accept pets (one pet per reservation), in one of our villas, "eleni", at no extra charge.</p>
                        </div>
                        <div className="w-full md:w-1/3 pr-8">
                            <StaticImage
                                src="../images/sleep.png"
                                loading="lazy"
                                width={35}
                                quality={100}
                                formats={["auto", "webp", "avif"]}
                                alt=""
                            />
                            <h3 className="val-h3">The garden and the terrace</h3>
                            <p>The large - fully fenced - safe, green garden with lawn, olive trees, is an ideal place for relaxation, both for adults and children. Each villa has its own built-in barbecue, as well as a large, covered terrace with an elegant cot. There is also free, secure private car parking.</p>
                        </div>
                        <div className="w-full md:w-1/3 pr-8">
                            <StaticImage
                                src="../images/location.png"
                                loading="lazy"
                                width={35}
                                quality={100}
                                formats={["auto", "webp", "avif"]}
                                alt=""
                            />
                            <h3 className="val-h3">Location</h3>
                            <p>White Stone Villas is located, just 1.5 km outside the village of Agios Nikolaos, in a green, quiet area, on the peninsula of Sithonia, Halkidiki, 98 km from the airport "Macedonia" of Thessaloniki. They are just 1.5 km from the famous, long, sandy beach of Trani Ammouda and the adjacent fishing village of Ormos Panagias (with cafes, taverns and ferries that depart daily for the famous peninsula of Mount Athos).</p>
                        </div>
                    </div>
                </div>
            </section>




            <section id="testimonial" className="testimonial-area py-20">
                <div className="container">
                    <div className="justify-center row">
                        <div className="w-full mx-4 lg:w-1/2">
                            <div className="pb-10 text-center section-title">
                                <h2 className="sld-h2" style={{ marginBottom: "1rem", fontSize: "36px" }}>Amazing Beaches</h2>
                                {/* <p >Stop wasting time and money designing and managing a website that doesn’t get results. Happiness guaranteed!</p> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="w-full">
                            <div className="row">
                                <div className="w-full lg:w-1/3 p-4">
                                    <div className="text-center">
                                        <div>
                                            <StaticImage
                                                src="../images/beach-img-3.jpg"
                                                loading="lazy"
                                                width={421}
                                                quality={100}
                                                formats={["auto", "webp", "avif"]}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <h6 className="sld-h2 text-left pt-6" style={{ marginBottom: "1rem" }}>Trani Ammoudia</h6>
                                            <p className="text-left">Trani Ammouda beach is located near Ormos Panagias. It is one of the largest sandy areas in Sithonia. The water is calm and shallow, the sand is fine and white and that is why you consider it an ideal destination for families.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/3 p-4">
                                    <div className="text-center">
                                        <div>
                                            <StaticImage
                                                src="../images/beach-img.jpg"
                                                loading="lazy"
                                                width={421}
                                                quality={100}
                                                formats={["auto", "webp", "avif"]}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <h6 className="sld-h2 text-left pt-6" style={{ marginBottom: "1rem" }}>Akti Karidi</h6>
                                            <p className="text-left">Karydi is a small precious diamond in the crown of Sithonia, an exotic beach with white sand, turquoise waters, white rocks and a pine grove very close to the coast. In addition, the water is shallow and warm. You can walk in the water for many meters from the shore.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/3 p-4">
                                    <div className="text-center">
                                        <div>
                                            <StaticImage
                                                src="../images/beach-img-2.jpg"
                                                loading="lazy"
                                                width={421}
                                                quality={100}
                                                formats={["auto", "webp", "avif"]}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <h6 className="sld-h2 text-left pt-6" style={{ marginBottom: "1rem" }}>Akti Kalogrias</h6>
                                            <p className="text-left">It is an organized beach and has two beach bars serving drinks and food. The location offers shade from pine trees and the sea is calm and has a unique turquoise color, its waters are shallow and the sand does not stick on you. Kalogria Beach is one of the places where you can enjoy the unique sunset of Sithonia.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="map" style={{ height: "100px" }}>

            </section>

            <section>
                <div
                    className="google-map fluid-map-height position-relative overflow-hidden"
                    id="googleMap"
                    css={`
                            position: relative;
                            overflow: hidden;
                        `}
                >
                    <div
                        css={`
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            background-color: rgb(229, 227, 223);
                            `}
                    >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12182.06851249169!2d23.7093238!3d40.2420373!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfbf950e15e461a3c!2sWhite%20Stone%20Villas!5e0!3m2!1sel!2sgr!4v1652175180434!5m2!1sel!2sgr" width="100%" height="650" style={{ border: "0" }} allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>

            </section>

            <section id="contact" style={{ height: "100px" }}>

            </section>

            <section className="contact-area py-20">
                <div className="container">
                    <div className="justify-center row">
                        <div className="w-full mx-4 lg:w-1/2">
                            <div className="pb-10 text-center section-title">
                                <h2 className="sld-h2" style={{ marginBottom: "1rem", fontSize: "36px" }}>Get In touch</h2>

                            </div>
                        </div>
                    </div>
                    <div className="justify-center row">
                        <div className="w-full lg:w-2/3">
                            <div className="contact-form">
                                <form id="contact-form" data-toggle="validator" onSubmit={(e) => contactMessage(e)}>
                                    <div className="row">
                                        <div className="w-full md:w-1/2">
                                            <div className="mx-4 mb-6 single-form form-group">
                                                <input type="text" id="name" name="name" placeholder="Your Name" data-error="Name is required." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-1/2">
                                            <div className="mx-4 mb-6 single-form form-group">
                                                <input type="email" id="email" name="email" placeholder="Your Email" data-error="Valid email is required." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-1/2">
                                            <div className="mx-4 mb-6 single-form form-group">
                                                <input type="text" id="subject" name="subject" placeholder="Subject" data-error="Subject is required." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-1/2">
                                            <div className="mx-4 mb-6 single-form form-group">
                                                <input type="text" id="phone" name="phone" placeholder="Phone" data-error="Phone is required." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="w-full">
                                            <div className="mx-4 mb-6 single-form form-group">
                                                <textarea rows="5" id="message" placeholder="Your Message" name="message" data-error="Please, leave us a message." required="required"></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        {/* <p className="mx-4 form-message"></p> */}
                                        <div className="mx-4 form-message">{error}</div>

                                        <div className="w-full flex justify-center">
                                            <button type="submit" className="mx-3 btn-anim">
                                                <span className="qodef-m-text">send message</span>
                                                <span className="qodef-m-background-holder"></span>
                                            </button>
                                        </div>
                                        <div className="text-center text-green-500 w-full mt-8" dangerouslySetInnerHTML={{ __html: emailResponse }}></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <footer id="footer" className="footer-area">
                <div className="mb-16 pt-16 footer-widget">
                    <div className="container">
                        <div className="row">
                            {/* <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 pr-8">
                                <div className="mb-8 footer-link">
                                    <h3 className="footer-title">About</h3>
                                    <p>One of the most exciting and fresh proposals for luxury accommodation in Halkidiki, comes from the twin stone villas "Dimitra" and "Eleni", in the area of Agios Nikolaos, on the Sithonia peninsula in Halkidiki.</p>
                                </div>
                            </div> */}
                            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
                                <div className="mb-8 footer-link">
                                    <h3 className="footer-title">Links</h3>
                                    <ul>
                                        {/* <li >
                                            <a className="page-scroll" href="#about">About</a>
                                        </li> */}
                                        <li >
                                            <a className="page-scroll" href="#villas">Villas</a>
                                        </li>
                                        <li >
                                            <a className="page-scroll" href="#services">Services</a>
                                        </li>
                                        <li>
                                            <a className="page-scroll" href="#map">Map</a>
                                        </li>
                                        <li >
                                            <a className="page-scroll" href="#contact">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="w-full sm:w-5/12 md:w-1/3 lg:w-1/4">
                                <div className="mb-8 footer-link">
                                    <h3 className="footer-title">Contact</h3>
                                    <p>
                                        A: <a href="https://goo.gl/maps/6vuC3YQ4GRNGq9mk8" target="blank" rel="noopener">Agios Nikolaos 630 78, Halkidiki</a><br />
                                        P: <a href="tel:+306988663613">+30 698 866 3613</a><br />
                                        E: <a href="mailto:whitestonevillasgr@gmail.com">whitestonevillasgr@gmail.com</a>
                                    </p>
                                </div>
                            </div>
                            <div className="w-full sm:w-7/12 md:w-1/3 lg:w-1/4">
                                <div className="mb-8 footer-newsletter">
                                    <h3 className="footer-title">Get Social</h3>
                                    <div className="flex flex-wrap">
                                        <div>
                                            <a href="https://www.facebook.com/White-stone-villas-dimitra-and-eleni-100132198988213" target={"_blank"} className="block" >
                                                <StaticImage
                                                    src="../images/fb.png"
                                                    loading="lazy"
                                                    width={40}
                                                    quality={100}
                                                    formats={["auto", "webp", "avif"]}
                                                    alt=""
                                                />
                                            </a>
                                        </div>
                                        <div className="pl-4">
                                            <a href="https://www.instagram.com/whitestonevillas/" target={"_blank"} >
                                                <StaticImage
                                                    src="../images/ig.png"
                                                    loading="lazy"
                                                    width={40}
                                                    quality={100}
                                                    formats={["auto", "webp", "avif"]}
                                                    alt=""
                                                />
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-copyright">
                    <div className="container flex justify-center">
                        <p>© Copyright <a href="https://w3vitals.com/" target="blank" rel="noopener"> W3Vitals</a></p>
                    </div>
                </div>
            </footer>


            <a className="back-to-top" href="#header"><span>↑</span></a>


        </div>
    )
}

export default IndexPage
